body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

aside {
  width: 100%;
  height: 100%;
  /* background: #222834; */
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.description {
  margin-bottom: 10px;
}

.dndnode {
  padding: 5px;
  margin-bottom: 2px;
  background: #fff;
  border: 1px solid #4b4a4a;
  border-left: 5px solid #4b4a4a;
  border-radius: 3px;
  cursor: grab;
  min-width: 150px;
}

.dndnode.status-finished {
  background-color: #90d67f;
  color: white;
}

.dndnode.type-bash {
  
}

.type-sql {
}



.dndnode.input {
  background-color: #e7f5ff;
}

.dndnode.output {
  background-color: #fff5f5;
}

.dndnode.start {
  background-color: #99ca9b;
}

.dndnode.decision {
  background-color: rgb(216, 159, 52);
}

/* Status is added as last. Last CSS Property is leading */

.status-finished {
  background-color: #b7dbc5;
  color: white;
}

.status-error {
  background-color: #f15555;
  color: white;
}

.status-dispatched {
  background-color: #2e35bb;
  color: white;
}

.status-aborted {
  background-color: #939393;
  color: white;
}

.status-pending {
  background-color: #efeeee !important;
  color: black;
}

.status-WorkflowWait {
  background-color: #d25707 !important;
  color: black;
}


  .status-unknownxx {
    background-color: gray !important;
    color: white;
  }