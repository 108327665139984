/* SchedulerProject.css */
.container-fullscreen {
    position:absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 128px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .iframe-fullscreen {
    width: 100%;
    height: 100%;
    border: none;
  }
  